.confirm-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    color: white;

    &__actions {
        display: flex;
        margin-top: 24px;
        justify-content: flex-end;
    }

    &__container {
        z-index: 999999;
    }

    &__message {
        font-size: 48px;
    }

    &__overlay {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
    }
}