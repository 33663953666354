.container {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 10vh;
  background: #25252c;
  border-bottom: #000000 1px solid;
  position: fixed;
  z-index: 1;
}

.right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.downloadbtn {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 148px;
  background-color: #1d52d3;
  border: none;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 15px;
}

.downloadbtn:hover{
  color: white;
}

.sharebtn {
  height: 45px;
  width: 148px;
  background-color: transparent;
  border: 1px #bdcbff solid;
  color: #bdcbff;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 15px;
}

.hamburger {
  padding: 0 30px;
}

.reactModal {
  z-index: 1;
}
