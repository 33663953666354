.tab-list {
  &__actions {
    display: flex;
    justify-content: space-between;
  }
  &__accordion-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;
    cursor: pointer;
  }
  &__accordion-details {
    display: none;
  }
  &__saved-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;
    &--children {
      padding-left: 12px;
    }
    &--is-opened {
      background-color: #4d4d4d;
    }
  }
  &__dropdown {
    transform: rotate(-90deg);
    transition: transform 0.2s ease;
  }

  // accordion expanded
  &__accordion--expanded {
    .tab-list__accordion-details {
      display: block;
    }
    .tab-list__dropdown {
      transform: rotate(0);
    }
  }
}