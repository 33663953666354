.active {
  background: #fff;
  border: 1px #0033cc dotted;
}

.active p {
  color: #0033cc;
}

.textContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  background: #b9c1d6;
  padding-left: 10px;
}

.activeTextContainer {
  background: #fff;
  text-align: left;
}
