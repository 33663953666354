.btn {
    height: 45px;
    width: 148px;
    border: none;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;

    &__caption {
      color: #ca4b4b;
    }
    &__primary {
        background-color: #1d52d3;
        color: white;
    }
    &__reset {
        height: 45px;
        width: 148px;
        background-color: transparent;
        border: 1px #bdcbff solid;
        color: #bdcbff;
        font-size: 12px;
        border-radius: 3px;
        cursor: pointer;
        margin-left: auto;
    }
}

