.shared-modal {
  padding: 24px;
  height: 200px;

  &__email-input {
    width: 100%;
  }

  &__bottom {
    margin-top: 12px;
  }
}
