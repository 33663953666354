.navbar {
    &__close-icon-container {
        color: white;
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        svg {
            cursor: pointer;
        }
    }
    &__copy {
      color: white;
    }
    &__left {
        display: flex;
        align-items: center;
        padding-left: 30px;
    }
    &__menu {
        color: white;
        cursor: pointer;
        width: 36px !important;
        height: 36px !important;
    }
    &__user {
        color: #fff;
        button {
            margin: 0;
        }
    }
    &__user--white-mode {
        color: #000;
    }
    &__info {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-right: 32px;
      }
    &__side-menu {
        padding: 12px;
        position: absolute;
        background-color: #25252c;
        top: 0;
        right: 0;
        height: 100vh;
        width: 400px;
    }

    @media (max-width: 768px) {
        &__logo {
            width: 100px;
        }
        &__side-menu {
            width: 100vw;
        }
    }
}
