.container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-bottom: auto;
}

.header {
  color: #bdcbff;
  font-size: 18px;
  font-weight: bold;
  margin-top: auto !important;
  margin-bottom: auto;
}

.subheader {
  color: #bdcbff;
  font-size: 10px;
  margin: 0;
}

.dupdocument {
  margin-left: auto;
  color: #bdcbff;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100%;
  width: -moz-available;    
  width: -webkit-fill-available;
  height: 50px;
  background: #45454f;
  border: none;
  color: #adadb1;
  padding: 20px;
}

.inputcontainer {
  margin-bottom: 20px;
}

.resetbtn {
  height: 45px;
  width: 148px;
  background-color: transparent;
  border: 1px #bdcbff solid;
  color: #bdcbff;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: auto;
}