.sidebar {
  display: flex;
  position: fixed;
  right: 0;
  transition: right 0.5s;
  height: 90vh;

  &--hide {
    right: -22vw;
  }

  &__btn {
    height: 60px;
    position: relative;
    top: 20px;
    border: none;
    cursor: pointer;
    width: 25px;
    background: #ddeaff;
    color: #1d52d3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn--flip {
    transform: scaleX(-1);
  }

  &__container {
    display: flex;
    flex-direction: column;
    background-color: #3a3a44;
    width: 22vw;
    overflow: auto;
  }

  &__hrule {
    margin: 0;
    border: none;
    border-top: 1px solid #7f7f7f;
  }

  @media (max-width: 768px) {
    &--hide {
      right: -100vw;
      .sidebar__btn {
        left: 0;
      }
    }
    &__btn {
      left: 25px;
    }
    &__container {
      width: 100vw;
    }
  }
}