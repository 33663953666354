.container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-bottom: auto;
}

.hcontainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.header {
  color: #bdcbff;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.subheader {
  color: #bdcbff;
  font-size: 10px;
  margin: 0;
}

.currentTabOnlyContainer {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.btnpdf {
  background-color: transparent;
  border: 3px #bdcbff solid;
  color: #bdcbff;
  border-radius: 3px;
  cursor: pointer;
  width: 194px;
  height: 48px;
  margin: 10px 0;
  font-weight: bold;
}

.btnlatex {
  background-color: transparent;
  border: 3px #bdcbff solid;
  color: #bdcbff;
  border-radius: 3px;
  cursor: pointer;
  width: 194px;
  height: 48px;
  margin: 10px 0;
  font-weight: bold;
}

.hide {
  display: none !important;
}
