.user-list {
  &__table {
    width: 100%;
    padding: 16px;
    max-width: 1020px;
    border-spacing: 0;
  }
  td, th {
    text-align: left;
    vertical-align: middle;
  }
  tr {
    line-height: 24px;
    &:hover {
      background-color: #dddddd;
    }
  }

  // modifier for semantic ui checkbox
  .checkbox {
    display: flex !important;
  }
}