.selected {
  background: #ffffff !important;
  color: #0033cc !important;
}

.selected input {
  color: #0033cc !important;
  font-weight: bold;
}
.container {
  width: 76vw;
  padding: 30px;
  min-height: 90vh;
  background: #2f2f39;
  transition: width 0.5s;
  display: flex;
    flex-direction: column;
}

.tabTitle {
  color: #b9c1d6;
  font-weight: bold;
}

.tabContainer {
  background: #45454e;
  color: #b9c1d6;
  padding: 16px 20px 10px 20px;
  font-weight: bold;
  border-right: 1px solid #45454f;
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.btn {
  background: transparent;
  border: none;
  color: #b9c1d6;
  transition: background 0.25s;
  padding-top: 5px;
  padding-bottom: 3.5px;
}

.btn:hover {
  cursor: pointer;
  background: rgb(185, 193, 214, 0.1);
}

.btn:focus {
  outline: none;
}
