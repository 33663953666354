body {
  /*hide scroll bar when there are a lot equations*/
  overflow: hidden;
}

button:focus {
  outline: none;
}

input:focus {
  outline: none;
}

li:focus {
  outline: none;
}

.instructionList li {
  margin-top: 10px;
}